.App {
  margin: 0 auto;
  width: 80%;
}
.navbar {
  margin-bottom: 30px;
}
table,
th,
td {
  text-align: center;
}
.float-start {
  padding-right: 18px;
}
.float-end {
  padding-left: 18px;
}

@media (max-width: 768px) {
  .float-start,
  .float-end {
    display: none !important;
  }
}

.book-img {
  display: inline-block !important;
}
